const state = {
    profitLossItem: null,
    viewBetsItem:null

};
const getters = {
    profitLossItem: state => state?.profitLossItem,
    viewBetsItem: state => state?.viewBetsItem,

};
const actions = {
    setProfitLossItem({ commit }, profitLossItem) {
        commit('setProfitLossItem', profitLossItem);
    },
    setViewBetsItem({ commit }, viewBetsItem) {
        commit('setViewBetsItem', viewBetsItem);
    },
};
const mutations = {
    setProfitLossItem(state, profitLossItem) {
        state.profitLossItem = profitLossItem;
    },
    setViewBetsItem(state, viewBetsItem) {
        state.viewBetsItem = viewBetsItem;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};