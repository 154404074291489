<template>
    <div class="modal fade worli-Bets-Modal Betamount"  data-bs-backdrop="static" data-bs-keyboard="false"  id="editstackModal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <div class="match-faq statement-match-faq">
                            <div class="match-head">
                                <h5>Edit Stake</h5>
                            </div>
                        </div>
                    </div>
                    <button type="button" @click="resetChips()" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"><span class="fa-solid fa-times"></span></button>
                </div>
                <div class="modal-body">
                    <div class="stakeed-sec withdraw-sec bg-transparent p-0 border-0 singlestakeed-sec">
                        <div class="thm-gradient-but">
                            <ul v-if="chips">
                                <li ><input type="number"
                                        @change="chips.chip_name_1 = getKFormatter(chips.chip_val_1)"
                                        v-model="chips.chip_val_1" class="btn-thm m-auto"></li>
                                <li ><input type="number"
                                        @change="chips.chip_name_2 = getKFormatter(chips.chip_val_2)"
                                        v-model="chips.chip_val_2" class="btn-thm m-auto"></li>
                                <li ><input type="number"
                                        @change="chips.chip_name_3 = getKFormatter(chips.chip_val_3)"
                                        v-model="chips.chip_val_3" class="btn-thm m-auto"></li>
                                <li ><input type="number"
                                        @change="chips.chip_name_4 = getKFormatter(chips.chip_val_4)"
                                        v-model="chips.chip_val_4" class="btn-thm m-auto"></li>
                            </ul>
                            <ul v-if="chips" class="mt-3">
                                <li ><input type="number"
                                        @change="chips.chip_name_5 = getKFormatter(chips.chip_val_5)"
                                        v-model="chips.chip_val_5" class="btn-thm m-auto"></li>
                                <li ><input type="number"
                                        @change="chips.chip_name_6 = getKFormatter(chips.chip_val_6)"
                                        v-model="chips.chip_val_6" class="btn-thm m-auto"></li>
                                <li ><input type="number"
                                        @change="chips.chip_name_7 = getKFormatter(chips.chip_val_7)"
                                        v-model="chips.chip_val_7" class="btn-thm m-auto"></li>
                                <li ><input type="number"
                                        @change="chips.chip_name_8 = getKFormatter(chips.chip_val_8)"
                                        v-model="chips.chip_val_8" class="btn-thm m-auto"></li>
                            </ul>
                        </div>
                    </div>
                    <div class="header-but  info-footer edit-stake-btn">
                        <button ref="close_button" @click="resetChips()" type="button"
                            class="thm-but thm-bor-but" data-bs-dismiss="modal">Cancel</button>
                        <a class="thm-but" @click="callChangeStakeValue()">Save</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import { kFormatter } from '@/shared/utils/formatter';


export default {
        name: "StackModal",
        data() {
            return {
                chips: null,
                chipsDummy: null,
                userData: null
            }
        },
        mounted() {
            this.chips = {...this.$store?.getters?.chips};
            this.chipsDummy = {...this.$store?.getters?.chips};
            this.userData = this.$store?.getters?.stateUser;
            
        },
        methods: {
            callChangeStakeValue() {

                let chipsData = {
                    "chips": {
                        ...this.chips
                    }
                }
                let headers = {
                    'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                }

                this.loading = true;
                api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
                    this.loading = false;
                    if (response && response.status == 200) {
                        this.$store.dispatch('setChips', this.chips);
                        this.showSuccessModalFunc('Saved successfully.')
                        this.$emit('save-btn');
                        this.$refs.close_button.click();
                    } else {

                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        this.showErrorModalFunc(error[0]);
                    }
                });
            },
            getKFormatter(num) {
                return kFormatter(num);
            },
            resetChips(){
                this.chips = {...this.$store?.getters?.chips};
            },
            showErrorModalFunc(message) {
				this.$emit('error-modal', message);
			},
			showSuccessModalFunc(message) {
				this.$emit('success-modal', message);
			},
        },
        emits: ['close-stake-modal', 'success-modal', 'error-modal','save-btn']
};
</script>