<template>
    <div class="left-side-menu-sec">
        <div class="inner-scroll-sec">
            <div class="logo-inplay-sec">
                <router-link :to="{ name: 'Inplay' }">
                    <figure>
                        <img :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo" alt="">
                    </figure>
                </router-link>
            </div>
            <div class="side-open-menu-bar">
                <ul>
                    <li>
                        <router-link :to="{ name: 'Inplay' }" class="side-dashboard-sec">
                            <img loading="lazy" src="@/assets/images/home.webp" alt="home">
                            <span>{{ translatedLangData('home','Home')}}</span>
                        </router-link>
                    </li>

                    <li v-for="(sport, n_index) in getNonCustomSportList()" :key="n_index">
                        <router-link :to="{name:'sports',params:{type:sport.slug,id:sport.slug}}" @click="set_tab(c_sport.id)" class="side-dashboard-sec">
                            <img v-if="sport.id == 4" src="@/assets/images/icon-cricket.webp" alt="icon-cricket">
                            <img v-else-if="sport.id == 1" src="@/assets/images/icon-football.webp" alt="icon-football">
                            <img v-else-if="sport.id == 2" src="@/assets/images/icon-tennis.webp" alt="icon-tennis">
                            <span> {{ sport.name }} </span>
                        </router-link>
                    </li>
                    <!-- This code is commented due to static data -->
                    <!-- <li>
                        <router-link :to="{ name: 'racing-category', params: { type: 'HORSE_RACE' } }"
                            class="side-dashboard-sec">
                            <img loading="lazy" src="@/assets/images/menu-h.webp" alt="" />
                            <span>Horse Racing</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'racing-category', params: { type: 'GREY_HOUND_RACING' } }"
                            class="side-dashboard-sec">

                            <img loading="lazy" src="@/assets/images/menu-4339.webp" alt="" />
                            <span>Greyhound Racing </span>
                        </router-link>
                    </li> -->
                    <li>
                        <router-link to="/matka" class="side-dashboard-sec">
                            <img loading="lazy" src="@/assets/images/menu-237896.webp" alt="menu-237896" />
                            <span>{{ translatedLangData('matka','Matka')}}</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/casino" class="side-dashboard-sec">
                            <img loading="lazy" src="@/assets/images/icon-casino.webp" alt="icon-casino" />
                            <span>{{ translatedLangData('casino','Casino')}}</span>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{ name: 'MainTerms' }" class="side-dashboard-sec">
                            <img loading="lazy" src="@/assets/images/terms.webp" alt="terms">
                            <span> T & C</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'Rules' }" class="side-dashboard-sec">
                            <img loading="lazy" src="@/assets/images/terms.webp" alt="terms">
                            <span>{{ translatedLangData('rules','Rules')}}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
export default {
    name: 'SideMenu',
    emits: ['close'],
    inject:['translatedLangData'],
    mounted() {
    },
    methods: {
        logoutBtnClick() {
            this.logoutServiceCall();
        },
        logoutServiceCall() {
            
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        // if (response?.data?.status == 0) {
                        //     this.$emit('error-modal', response.data.debug[0])
                        // }
                        // else {
                        // this.$emit('success-modal', response?.data?.message);
                        // remove all cookie
                        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                        this.$store.dispatch('resetAllState');
                        this.$emit('close_sidebar');
                        this.$router.push('/');
                        // }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error Login : ", error);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        set_tab(name) {
            this.$store.commit('set_sport_tab', name)
        },
        getCustomSportList() {
            return this.$store.getters?.sports_list?.custom
        },

        getNonCustomSportList() {
            return this.$store.getters?.sports_list?.non_custom
        },
        getUserID() {
            return this.$store.getters?.stateUser?.userid
        }
    }

}
</script>

<style></style>