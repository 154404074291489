<template>
    <footer class="mobile-view-footer">
        <a v-if="siteSettings && siteSettings?.business_type == 2" href='javascript:void(0);' @click="getParentWhatsappNumber" data-bs-toggle="modal" data-bs-target="#whatsup-depositModal" class="footer-fix-whatsapp" aria-label="Open WhatsApp for Deposit">
            <img loading="lazy" src="@/assets/images/whatsapp.webp" alt="whatsapp">
        </a> 
        <div class="page-footer-sec">
            <ul>
                <!-- <li :class="{'active':selectedTab=='IN_PLAY'}" @click="selectedTab='IN_PLAY'">
                    <router-link to="/" class="inplay-blink">
                        <img loading="lazy" src="@/assets/images/in-play.webp" alt=""> <br>
                        <span>In-Play</span>
                    </router-link>
                </li>
                <li :class="{'active':selectedTab=='MULTI'}" @click="selectedTab='MULTI'">
                    <router-link :to="{ name: 'MultiMarket' }">
                        <img loading="lazy" src="@/assets/images/multi.webp" alt=""> <br>
                        <span>Multi</span>
                    </router-link>
                </li>
                <li :class="{'active':selectedTab=='HOME'}" @click="selectedTab='HOME'" >
                    <router-link to="/">
                        <img loading="lazy" src="@/assets/images/place.webp" alt=""> <br>
                        <span>Home</span>
                    </router-link>
                </li>
                <li :class="{'active':selectedTab=='CASINO'}" @click="selectedTab='CASINO'">
                    <router-link to="/casino" class="inplay-blink">
                        <img loading="lazy" src="@/assets/images/icon-casino.webp" alt=""> <br>
                        <span>Casino</span>
                    </router-link>
                </li>
                <li :class="{'active':selectedTab=='INT_CASINO'}" @click="selectedTab='INT_CASINO'" >
                    <router-link to="/int-casino" class="inplay-blink">
                        <img loading="lazy" src="@/assets/images/icon-casino.webp" alt=""> <br>
                        <span>Icasino</span>
                    </router-link>
                </li> -->
                <li :class="this.$route.name == 'Inplay' ? 'active' : ''">
                    <router-link :to="{ name: 'Inplay' }" >
                        <img loading="lazy" src="@/assets/images/in-play.webp" alt="In-Play Icon"> <br>
                        <span>{{ translatedLangData('inplay-footer-label','In-Play')}}</span>
                    </router-link>
                </li>
                
                <li :class="this.$route.name == 'MultiMarket' ? 'active' : ''">
                    <router-link :to="{ name: 'MultiMarket' }">
                        <img loading="lazy" src="@/assets/images/multi.webp" alt="Multi icon"> <br>
                        <span>{{ translatedLangData('multi','Multi')}}</span>
                    </router-link>
                </li>
                <li  :class="this.$route.params.id == 4 ? 'active' : ''">
                    <a @click="callHomeFunction()">
                        <img loading="lazy" src="@/assets/images/place.webp" alt="place"> <br>
                        <span>{{ translatedLangData('home','Home')}}</span>
                    </a>
                </li>
                <li >
                    <router-link to="/casino" >
                        <img loading="lazy" src="@/assets/images/icon-casino.webp" alt="icon-casino"> <br>
                        <span>{{ translatedLangData('casino','Casino')}}</span>
                    </router-link>
                </li>
                <li  >
                    <router-link to="/int-casino" >
                        <img loading="lazy" src="@/assets/images/int-casino.webp" alt="int-casino" class="invert-img"> <br>
                        <span>{{ translatedLangData('icasino','Icasino')}}</span>
                    </router-link>
                </li>
                
            </ul>
        </div>
        <!--Whatsup Deposit Modal -->
    <div class="modal fade whatsup-modal-wrapper" id="whatsup-depositModal" tabindex="-1" aria-labelledby="whatsupModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Customer Support</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="fa-solid fa-times"></span></button>
                </div>
                <div class="modal-body whatsup-modal-information">
                            <div v-if="whatsappNumbers && whatsappNumbers.length > 0">
                            <div v-for="(data, index) in whatsappNumbers" :key="index">
                                <a :href="'https://wa.me/' + data.whatsapp_number" target="_blank" class="btn thm-btn call-now">
                                    <img src="@/assets/images/whatsapp-icon.webp" alt="whatsapp">
                                    {{ data.category_name }}
                                </a>      
                            </div>
                        </div>
                        <div v-else-if="siteSettings && (siteSettings.social_link?.whatsappno1 || siteSettings.social_link?.whatsappno2)">
                        <template v-for="number in [siteSettings.social_link?.whatsappno1, siteSettings.social_link?.whatsappno2]">
                            <a v-if="number" 
                                :key="number" 
                                :href="'https://wa.me/' + number" 
                                target="_blank" 
                                class="btn thm-btn call-now">
                                <img src="@/assets/images/whatsapp-icon.webp" alt="whatsapp">{{ number }}
                            </a>
                        </template>
                    </div>
                    <div v-else>
                        <p>No WhatsApp numbers available.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--Whatsup Deposit Modal -->
    </footer>
</template>

<script>
export default {
    name: 'MobileFooter',
    inject:['translatedLangData'],
    data(){
        return{
            selectedTab:'IN_PLAY',
            whatsappNumbers:[]
        }
    },
    computed:{
        siteSettings(){
            return this.$store.getters.siteSettings
        }
    },
    methods:{
        callHomeFunction() {
            this.$router.push({name: 'sports', params: { type: 'cricket', id: 4 }});
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        async getParentWhatsappNumber() {
            if (this.checkIsLogin()) {
                this.loading = true;
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }
                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();

                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.showErrorModalFunc(error[0]);
                }
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }

}
</script>

<style></style>