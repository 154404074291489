const AccountModule = () => import(/* webpackChunkName: "account-module" */ './views/Module.vue');
const Profile = () => import( /* webpackChunkName: "profile" */'./views/Profile.vue');
const AccountStatement = () => import(/* webpackChunkName: "account" */ './views/AccountStatement.vue');
const EditStakes = () => import(/* webpackChunkName: "account" */ '@/modules/account/components/EditStakes.vue'); 
const BetHistory = () => import(/* webpackChunkName: "betHistory" */ './views/BetHistory.vue');
const ProfitLossDetail = () => import(/* webpackChunkName: "ProfitLossDetail" */ './views/ProfitLossDetail.vue');
const AccountRoutes =   {
    path: '/',
    component: AccountModule,
    children: [
      {
        path: '/profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: '/statement/:type',
        name: 'AccountStatement',
        component: AccountStatement
      },
      {
        path: '/edit-stakes',
        name: 'EditStakes',
        component: EditStakes
      },
      {
        path:'/bet-history',
        name:'betHistory',
        component:BetHistory
      },
      {
        path:'/profit-loss-detail',
        name:'profitLossDetail',
        component:ProfitLossDetail
      },
    ],
  }

export default AccountRoutes;