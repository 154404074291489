<template>
    <div class="inplay-details-page-section">
        <div class="container-fluid">
            <div class="input-field-section trm-condations-sec">
                <div class="row">
                    <div class="col-12">
                        <div class="rule-details-page">
                            <p>Terms & Conditions-</p>
                            <p>Description: Initial Terms and Conditions replacing general rules</p>
                            <p>Introduction</p>
                            <p>These terms and conditions and the documents referred and linked to below (the “Terms”) set
                                out the basis upon which the website operated under the Website and its related or connected
                                services (collectively, the “Service”) will be provided to you.</p>
                            <p>Please read these terms very carefully as they form a binding legal agreement between you -
                                our customer (the “Customer”) - and us. By opening an account (the “Account”) and using the
                                Service you agree to be bound by these terms, together with any amendment which may be
                                published from time to time.</p>
                            <p>If anything is not clear to you please contact us using the contact details below by mail
                                only: info.tigerexch@protonmail.com</p>
                            <p>General Terms</p>
                            <p>We reserve the right to amend the terms (including to any documents referred and linked to
                                below) at any time. When such amendment is not substantial, we may not provide you with
                                prior notice. You will be notified in advance for material changes to the terms and may
                                require you to re-confirm acceptance to the updated terms before the changes come into
                                effect. If you object to any such changes, you must immediately stop using the service and
                                the termination provisions below will apply. Continued use of the service indicates your
                                agreement to be bound by such changes. Any bets not settled prior to the changed terms
                                taking effect will be subject to the pre-existing terms.</p>
                            <p>If at any time you are in any doubt about how to place bets or otherwise use the service you
                                should refer back to these terms or contact our customer service department (Customer
                                Service Department) at info.tigerexch@protonmail.com</p>
                            <p>Your Obligations</p>
                            <p>You agree that at all times when using the Service:</p>
                            <div class="obligations-sec">
                                <ul>
                                    <li>
                                        <p>You are over 18 years of age (or over the age of majority as stipulated in the
                                            laws of the jurisdiction applicable to you) and can enter into a binding legal
                                            agreement with us.</p>
                                    </li>
                                    <li>
                                        <p>It is the User’s responsibility to check and enter this site only if the user is
                                            in a country where it is lawful to place bets on the service (if in doubt, you
                                            should seek local legal advice). It is your responsibility to ensure that your
                                            use of the service is legal.</p>
                                    </li>
                                    <li>
                                        <p>When sending money to us you are authorised to do so e.g. you are the authorised
                                            user of the debit/credit card or other payment method you use.</p>
                                    </li>
                                    <li>
                                        <p>You will not, by participating in the Services and/or placing bets be placed in a
                                            position of actual, potential or perceived conflict of interest in any manner.
                                        </p>
                                    </li>
                                    <li>
                                        <p>You have never failed to pay, or attempted to fail to pay a liability on a bet.
                                        </p>
                                    </li>
                                    <li>
                                        <p>You are acting solely on your own behalf as a private individual in a personal
                                            capacity and not on behalf of another party or for any commercial purposes.</p>
                                    </li>
                                    <li>
                                        <p>By placing bets you may lose some or all of your money lodged with us in
                                            accordance with these terms and you will be fully responsible for that loss.</p>
                                    </li>
                                    <li>
                                        <p>You must use the service for legitimate betting purposes only and must not nor
                                            attempt to manipulate any market or element within the service in bad faith or
                                            in a manner that adversely affects the integrity of the Service or us.</p>
                                    </li>
                                    <li>
                                        <p>When placing bets on the service you must not use any information obtained in
                                            breach of any legislation in force in the country in which you were when the bet
                                            was placed.</p>
                                    </li>
                                    <li>
                                        <p>You must make all payments to us in good faith and not attempt to reverse a
                                            payment made or take any action which will cause such payment to be reversed by
                                            a third party in order to avoid a liability legitimately incurred.</p>
                                    </li>
                                    <li>
                                        <p>You must otherwise generally act in good faith in relation to us of the service
                                            at all times and for all bets made through the service.</p>
                                    </li>
                                </ul>
                            </div>
                            <p>Registration <br> You agree that at all times when using the service:</p>
                            <p>In order to protect the integrity of the service and for other operational reasons we reserve
                                the right to refuse to accept a registration application from any applicant at our sole
                                discretion and without any obligation to communicate a specific reason.</p>
                            <p>Before using the service, you must personally complete the registration form and read and
                                accept these terms. In order to start betting on the service, we will require you to become
                                a verified Customer which includes passing certain checks. You may be required to provide a
                                valid proof of identification and any other document as it may be deemed necessary.</p>
                            <p>This includes but is not limited to, a picture ID (copy of passport, driver’s licence or
                                national ID card) and a recent utility bill listing your name and address as proof of
                                residence to the minimum. We reserve the right to suspend wagering or restrict Account
                                options on any Account until the required information is received. This procedure is a
                                statutory requirement and is done in accordance with the applicable gaming regulation and
                                the anti-money laundering legal requirements. Additionally, you will need to fund your
                                Account using the payment methods set out on the payment section of our Website.</p>
                            <p>You must provide complete and accurate information about yourself, inclusive of a valid name,
                                surname, address and email address, and update such information in the future to keep it
                                complete and accurate. It is your responsibility to keep your contact details up to date on
                                your account. Failure to do so may result in you failing to receive important account
                                related notifications and information from us, including changes we make to these terms. We
                                identify and communicate with our Customers via their Registered Email Address. It is the
                                responsibility of the Customer to maintain an active and unique email account, to provide us
                                with the correct email address and to advise website of any changes in their email address.
                                Each Customer is wholly responsible for maintaining the security of his Registered Email
                                Address to prevent the use of his Registered Email Address by any third party. websiteexch
                                shall not be responsible for any damages or losses deemed or alleged to have resulted from
                                communications between websiteexch and the Customer using the Registered Email Address. Any
                                Customer not having an email address reachable by website will have his Account suspended
                                until such an address is provided to us. We will immediately suspend your Account upon
                                written notice to you to this effect if you intentionally provide false or inaccurate
                            personal information. We may also take legal action against you for doing so in certain
                            circumstances and/or contact the relevant authorities who may also take action against you.
                        </p>
                        <p>You are only allowed to register one Account with the service. Accounts are subject to
                            immediate closure if it is found that you have multiple Accounts registered with us.</p>
                        <p>This includes the use of representatives, relatives, associates, affiliates, related parties,
                            connected persons and/ or third parties operating on your behalf.</p>
                        <p>In order to ensure your financial worthiness and to confirm your identity, we may use any
                            third party information providers we consider necessary.</p>
                        <p>You must keep your password for the service confidential. Provided that the Account
                            information requested has been correctly supplied, we are entitled to assume that bets,
                            deposits and withdrawals have been made by you. We advise you to change your password on a
                            regular basis and never disclose it to any third party. Passwords must contain at least one
                            letter, one number and one special character and must be at least eight characters long. It
                            is your responsibility to protect your password and any failure to do so shall be at your
                            sole risk and expense. You must log out of the Service at the end of each session. If you
                            believe any of your Account information is being misused by a third party, or your Account
                            has been hacked into, or your password has been discovered by a third party, you must notify
                            us immediately by email using your registered Email Address to info.tigerexch@protonmail.com
                        </p>
                        <p>You must notify us if your registered email Address has been hacked into, we may, however,
                            require you to provide additional information/ documentation so that we can verify your
                            identity. We will immediately suspend your Account once we are aware of such an incident. In
                            the meantime you are responsible for all activity on your Account including third party
                            access, regardless of whether or not their access was authorised by you.</p>
                        <p>You must not at any time transmit any content or other information on the service to another
                            Customer or any other party by way of a screen capture (or other similar method), nor
                            display any such information or content in a frame or in any other manner that is different
                            from how it would appear if such Customer or third party had typed the URL for the service
                            into the browser line;</p>
                        <p>When registering, you will be required to choose the currency in which you will operate your
                            account. This will be the currency of your deposits, withdrawals and bets placed and matched
                            into the service as set out in these terms. Some payment methods do not process in all
                            currencies. In such cases a processing currency will be displayed, along with a conversion
                            calculator available on the page.</p>
                        <p>We are under no obligation to open an account for you and our website sign-up page is merely
                            an invitation to treat. It is entirely within our sole discretion whether or not to proceed
                            with the opening of an account for you and, should we refuse to open an Account for you, we
                            are under no obligation to provide you with a reason for the refusal.</p>
                        <p>Upon receipt of your application, we may be in touch to request further information and/ or
                            documentation from you in order for us to comply with our regulatory and legal obligations.
                        </p>

                        <p>Restricted Use <br>
                            3.1 You must not use the Service:</p>
                        <p>if you are under the age of 18 years (or below the age of majority as stipulated in the laws
                            of the jurisdiction applicable to you) or if you are not legally able to enter into a
                            binding legal agreement with us;</p>
                        <p>to collect nicknames, e-mail addresses and/or other information of other Customers by any
                            means (for example, by sending spam, other types of unsolicited e-mails or the unauthorised
                            framing of, or linking to, the Service);</p>
                        <p>to disrupt or unduly affect or influence the activities of other Customers or the operation
                            of the Service generally;.</p>
                        <p>to promote unsolicited commercial advertisements, affiliate links, and other forms of
                            solicitation which may be removed from the Service without notice;.</p>
                        <p>in any way which, in our reasonable opinion, could be considered as an attempt to: (i) cheat
                            the Service or another Customer using the Service; or (ii) collude with any other Customer
                            using the Service in order to obtain a dishonest advantage;</p>
                        <p>to scrape our odds or violate any of our Intellectual Property Rights; or.</p>
                        <p>for any unlawful activity whatsoever.</p>
                        <p>3.2 You cannot sell or transfer your account to third parties, nor can you acquire a player
                            account from a third party.</p>
                        <p>3.3 You may not, in any manner, transfer funds between player accounts.</p>
                        <p>3.4 We may immediately terminate your Account upon written notice to you if you use the
                            Service for unauthorised purposes. We may also take legal action against you for doing so in
                            certain circumstances.</p>
                        <p>Privacy <br>
                            Any information provided to us by you will be protected and processed in strict accordance
                            with these Terms and our Privacy Policy.</p>
                        <p>We will not reveal the identity of any person who places bets using the service unless the
                            information is lawfully required by competent authorities such as Regulators, the Police
                            (e.g. to investigate fraud, money laundering or sports integrity issues), or by Financial
                            Entities such as banks or payment suppliers or as permitted from time to time pursuant to
                            the Privacy Policy.</p>
                        <p>Upon registration, your information will be stored in our database. This means that your
                            personal information may be transferred outside the European Economic Area (EEA) to
                            jurisdictions that may not provide the same level of protection and security as applied
                            within the EU or EEA. By agreeing to these Terms you agree to the transfer of your personal
                            information for the purpose of the provision of the Service object of this agreement and as
                            further detailed in our Privacy Policy.</p>
                        <p>Your Account <br>
                            We accept Accounts in multiple currencies, please refer to:</p>
                        <p>https://website.com account balances and transactions appear in the currency selected when
                            the account was originally opened.</p>
                        <p>We do not give credit for the use of the service.</p>
                        <p>We may close or suspend an Account and refund any monies held if you are not or we reasonably
                            believe that you are not complying with these Terms, or to ensure the integrity or fairness
                            of the Service or if we have other reasonable grounds to do so. We may not always be able to
                            give you prior notice.</p>
                        <p>We reserve the right to suspend an account without prior notice and return all funds.
                            Contractual obligations already matured will however be honoured.</p>
                        <p>We reserve the right to refuse, restrict, cancel or limit any wager at any time for whatever
                            reason, including any bet perceived to be placed in a fraudulent manner in order to
                            circumvent our betting limits and/ or our system regulations.</p>
                        <p>If we close or suspend your account due to you not complying with these terms, we may cancel
                            and/or void any of your bets.</p>
                        <p>If any amount is mistakenly credited to your account it remains our property and when we
                            become aware of any such mistake, we shall notify you and the amount will be withdrawn from
                            your Account.</p>
                        <p>If, for any reason, your account goes overdrawn, you shall be in debt to us for the amount
                            overdrawn.</p>
                        <p>You must inform us as soon as you become aware of any errors with respect to your Account.
                        </p>
                        <p>Customers have the right to self-exclude themselves from website.com.. These requests have to
                            be received from the Customer’s Registered Email Address and have to be sent to
                            info.tigerexch@protonmail.com</p>
                        <p>Customers may set limitations on the amount they may wager and lose. Such request has to be
                            sent from the Customer’s Registered Email Address to info.tigerexch@protonmail.com.
                            Implementation and increasing of limits shall be processed diligently, however, any request
                            for removing or reducing limitations shall be done after a cooling-off period of seven days
                            following your request.</p>
                        <p>You cannot transfer, sell, or pledge Your Account to another person. This prohibition
                            includes the transfer of any assets of value of any kind, including but not limited to
                            ownership of accounts, winnings, deposits, bets, rights and/or claims in connection with
                            these assets, legal, commercial or otherwise. The prohibition on said transfers also
                            includes however is not limited to the encumbrance, pledging, assigning, usufruct, trading,
                            brokering, hypothecation and/or gifting in cooperation with a fiduciary or any other third
                            party, company, natural or legal individual, foundation and/or association in any way shape
                            or form.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></template>

<script>
export default {
    name: 'MainTerms'
    

}
</script>

<style></style>