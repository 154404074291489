const MyBetstModule = () => import(/* webpackChunkName: "my-bets-module" */ './views/Module.vue');
const UnsttledBets = () => import(/* webpackChunkName: "unsettled-bets" */ './views/UnsettledBets.vue');

const BetsRoutes = {
    path: '/',
    component: MyBetstModule,
    children: [
        {
            path: 'unsettled-bets',
            name: 'UnsttledBets',
            component: UnsttledBets
        }
    ],
}

export default BetsRoutes;