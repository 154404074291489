const state = {
    sport_tab: null,
    siteSettings: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    favouriteGames:null,
    globalLoader:false,
    captchaData: null,
    languagesData:null,
    sports_list:null,

    siteVersion:null,
    underMaintenance:null,
};
const getters = {  
    siteSettings: state => state?.siteSettings,
    languagesData: state => state?.languagesData,
    gamesData: state => state?.gamesData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    clickBetActive: state => state?.clickBetValue?.find(item => item.isSelected) ?? false,
    favouriteGames:state => state?.favouriteGames,
    globalLoader:state => state?.globalLoader,
    captchaData: state => state?.captchaData,
    sports_list: state => state?.sports_list,
    siteVersion: state => state?.siteVersion,
    underMaintenance: state => state?.underMaintenance,
};
const actions = {
    set_sport_tab({ commit},sport_tab) {
        commit('set_sport_tab',sport_tab);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },

    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
    setSportsList({ commit }, sports_list) {
        commit('setSportsList', sports_list);
    },
    setSiteVersion({ commit }, siteVersion) {
        commit('setSiteVersion', siteVersion);
    },
    setUnderMaintenance({ commit }, underMaintenance) {
        commit('setUnderMaintenance', underMaintenance);
    },

      
};
const mutations = {
    set_sport_tab(state, tab) {
        state.sport_tab = tab
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
    setSportsList(state, sports_list) {
        state.sports_list = sports_list;
    },
    setSiteVersion(state, siteVersion) {
        state.siteVersion = siteVersion;
    },
    setUnderMaintenance(state, underMaintenance) {
        state.underMaintenance = underMaintenance;
    },

};
export default {
    state,
    getters,
    actions,
    mutations
};