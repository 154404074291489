const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const Casino = () => import(/* webpackChunkName: "casino" */ './views/Casino.vue');
const IntCasino = () => import(/* webpackChunkName: "casino" */ './views/IntCasino.vue');

const GamesRoutes = {
    path: '/',
    component: GamesModule,
    children: [
        {
            path: 'casino',
            name: 'casino',
            component: Casino
        },
        {
            path: 'int-casino',
            name: 'int-casino',
            component: IntCasino
        }
    ],
}

export default GamesRoutes;