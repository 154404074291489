<template>
    <div class="modal fade change-lang-modal " id="language_selection_pop_up" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>{{ translatedLangData('change-language','Change your language')}}</h3>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="ResetLang()"><img
                            src="@/assets/images/close-btn.webp" alt="close-btn"></button>
                </div>
                <div class="modal-body">
                    <section class="sub-header-wrapper">

                        <div class="langauage-content">
                            <div class="search-lang-input">
                               <input type="search" :placeholder="translatedLangData('search-key', 'Search')" v-model="searchTerm" class="form-control">
                                <button class="btn link-btn"><img loading="lazy" src="@/assets/images/search-grey-icon.webp"
                                        alt="search-grey-icon"></button>
                            </div>
                            <div class="custom-language-list">
                                <ul>
                                    <li v-for="item in filteredLanguages" :key="item.lang_code"
                                        :class="{ 'active': selectedValue?.lang_code == item?.lang_code }">
                                        <div class="form-check" @click="this.selectedValue = item">
                                            <label class="form-check-label">
                                                {{ item.lang_name }}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="two-btns">
                            <ul class="cmn-ul-list">
                                <li>
                                    <button class="btn thm-bor-but" data-bs-dismiss="modal" @click="ResetLang()">{{ translatedLangData('close','Close')}}</button>
                                </li>
                                <li>
                                    <button class="btn thm-but" @click="selectedlanguage">{{ translatedLangData('update','Update')}}</button>
                                </li>
                            </ul>
                        </div>

                    </section>
                </div>
            </div>
        </div>
    </div>


</template>
<script>

import IFrameModal from '@/modules/games/components/IFrameModal.vue';
import LanguageService from '@/shared/services/language-service.js'

export default {
    name: 'GamesPop1',
    data() {
        return {
            openIframeModal: false,
            selectedValue: '',
            searchTerm: ''
        }
    },
    inject:['translatedLangData'],
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        languagesData() {
            return this.$store.getters.languagesData;
        },
        filteredLanguages() {
            if (this.searchTerm) {
                const searchTermLower = this.searchTerm.toLowerCase();
                return this.languagesData.filter(item => item.lang_name.toLowerCase().includes(searchTermLower));
            }
            return this.languagesData
        },

    },
    mounted(){
        this.ResetLang();
    },
    methods: {
        ResetLang(){
            if (localStorage.getItem('selectedLanguageCode')) {
                this.selectedValue = this.languagesData.find(item => item.lang_code == localStorage.getItem('selectedLanguageCode'));
            } else {
                this.selectedValue = this.languagesData.find(item => item.lang_code == this.siteSettings?.language_code);
            }
            this.currentLanguage = this.selectedValue?.lang_name;
        },
        yesAgreeMethodCall() {
            this.openIframeModal = true;
            this.$refs.iframe_pop_modal.click();
        },
        closeModal() {
            this.openIframeModal = false
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        async selectedlanguage() {
            localStorage.setItem('selectedLanguageCode', this.selectedValue?.lang_code);
            if (this.selectedValue && this.selectedValue.lang_code === 'en') {
            localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
            this.$parent.langData = "";
            this.showSuccessModalFunc(this.translatedLangData('language-updated-successfully','language updated successfully'));
            document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
            } 
            else if (this.selectedValue) {
                const aws_url_language = this.siteSettings?.aws_url + '/' + this.selectedValue?.lang_url
                this.$parent.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
                if (this.$parent.langData) {
                    localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
                    this.showSuccessModalFunc(this.translatedLangData('language-updated-successfully','language updated successfully'));
                    document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
                } else {
                    localStorage.removeItem('selectedLanguageUrl');
                    this.showErrorModalFunc(this.translatedLangData('language-data-is-not-available','language data is not available'))
                }
            }
        },
    },
    components: {
        IFrameModal
    }
}
</script>