<template>
    <div class="inner-body login-body-bg">

        <div class="register-header-wrapper">

            <div class="register-header-sec">
                <div class="container">
                    <div class="register-header-con">
                        <div class="black-arrow">
                            <!-- <a href="login.html"><img loading="lazy" src="@/assets/images/back-arrow.webp" alt=""></a> -->
                        </div>
                        <div class="register-header-det">
                            <div class="thm-heading">
                                <h3>Login</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="register-header-banner">
                <div class="container">
                    <div class="register-banner">
                        <div class="register-banner-logo">
                            <a href="#">
                                <!-- <img loading="lazy" src="@/assets/images/logo.webp" alt=""> -->
                            </a>
                        </div>
                        <div class="thm-heading">
                            <h2>Create New Password</h2>
                            <p>your new password must be different from previous used passwords.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-wrapper">
                <div class="container">
                    <div class="form-sec">
                        <form>
                            <div class="form-con">


                                <div class="form-con-input">
                                    <label for="exampleInputEmail1" class="form-label">Password </label>
                                    <div class="input-select">
                                        <input type="password" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter here">

                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <!-- <img class="user-in-img show" src="@/assets/images/lock-icon.webp" alt=""> -->
                                            </div>
                                        </div>

                                        <div class="right-input-sel">
                                            <div class="left-input-img">
                                                <!-- <button class="border-0 bg-transparent"><img class="user-in-img show"
                                                        src="@/assets/images/eye-icon.webp" alt=""></button> -->

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-con-input">
                                    <label for="exampleInputEmail1" class="form-label">Confirm Password </label>
                                    <div class="input-select">
                                        <input type="password" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter here">

                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <!-- <img class="user-in-img show" src="@/assets/images/lock-icon.webp" alt=""> -->
                                            </div>
                                        </div>

                                        <div class="right-input-sel">
                                            <div class="left-input-img">
                                                <!-- <button class="border-0 bg-transparent"><img class="user-in-img show"
                                                        src="@/assets/images/eye-icon.webp" alt=""></button> -->

                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div class="form-con-input register-btn ">
                                    <div class="form-btn ">
                                        <a href="#" class="thm-btn">Reset</a>
                                    </div>
                                    <div class="forgot-sub-con text-center">
                                        <h5>Already have an account?
                                            <router-link :to="{ name: 'Login' }">
                                                <span>Login?</span>
                                            </router-link>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewPassword',
    mounted() {
        document.body.classList.add('login-body-bg','inner-body');
    },
    unmounted() {
        document.body.classList.remove('login-body-bg','inner-body');
    }

}
</script>

<style></style>