<template>
    <div class="modal fade worli-Bets-Modal Betamount" id="clickBetValue" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <div class="match-faq statement-match-faq">
                            <div class="match-head">
                                <h5>{{ translatedLangData('click-bet-value','Click Bet Value')}}</h5>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"><span class="fa-solid fa-times"></span></button>
                </div>
                <div class="modal-body">
                    <div class="stakeed-sec withdraw-sec bg-transparent p-0 border-0 singlestakeed-sec">
                        <div class="thm-gradient-but">
                            <ul v-if="inputvalue">
                                <li><input  @click="selectInputValue(0)" :class="{ 'border-input': inputvalue[0].isSelected }"
                                        :value="inputvalue[0].value" class="btn-thm m-auto" maxlength="4" @input="validateNumber($event, 0)">
                                </li>
                                <li><input  @click="selectInputValue(1)" :class="{ 'border-input': inputvalue[1].isSelected }"
                                        :value="inputvalue[1].value" class="btn-thm m-auto" maxlength="4" @input="validateNumber($event, 1)">
                                </li>
                                <li><input @click="selectInputValue(2)" :class="{ 'border-input': inputvalue[2].isSelected }"
                                        :value="inputvalue[2].value" class="btn-thm m-auto" maxlength="4"  @input="validateNumber($event, 2)">
                                </li>
                                <li><input @click="selectInputValue(3)" :class="{ 'border-input': inputvalue[3].isSelected }"
                                        :value="inputvalue[3].value" class="btn-thm m-auto" maxlength="4"  @input="validateNumber($event, 3)">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="min-max-section">
                        <div class="min-max-con">
                            <h6>{{ translatedLangData('min','min')}} <span>100.00</span></h6>
                        </div>
                        <div class="min-max-icon">
                            <img src="@/assets/images/min-max-icon.webp" alt="min-max-icon">
                        </div>
                        <div class="min-max-con">
                            <h6>{{ translatedLangData('max','max')}} <span>5,000.00</span></h6>
                        </div>
                    </div>
                    <div class="header-but  info-footer edit-stake-btn">
                        <button ref="close_btn" type="button" class="thm-but thm-bor-but"
                            data-bs-dismiss="modal" @click="closeModal()">{{ translatedLangData('cancel','Cancel')}}</button>
                        <!-- <button ref="close_btn" type="button" class="thm-but thm-bor-but"
                            data-bs-dismiss="modal" @click="resetClickBet()">{{ translatedLangData('cancel','Cancel')}}</button> -->
                            <a @click="saveClickBetValue()" class="btn thm-but sumbit-bet-btn" :class="{ 'btn-loading btn-clickbet-loader': loading }">
                                {{ translatedLangData('save','Save')}}
                                <span class="tig-loader-btn"><b></b><b></b><b></b></span>
                            </a>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
export default {
    name: "ClickBetModal",
    inject:['translatedLangData'],
    data() {
        return {
            inputvalue: null,
            selectedValue:false,
            clickBetModal: null,
            loading: false,
        }
    },
    mounted() { 
        // this.resetClickBet();
        if (this.getClickBetValue()) {
            this.inputvalue = JSON.parse(JSON.stringify([...this.getClickBetValue()]))
        }
        else {
            this.inputvalue = [
                {
                    value: 1000,
                    isSelected: true,
                },
                {
                    value: 2000,
                    isSelected: false,
                },
                {
                    value: 3000,
                    isSelected: false,
                },
                {
                    value: 4000,
                    isSelected: false,
                }
            ]
        }
        this.clickBetModal = new Modal('#clickBetValue');
    },
    methods: {
        getClickBetValue() {
            return this.$store.getters.clickBetValue?[...this.$store.getters.clickBetValue]:null;
        },

        validateNumber(evt, index) {
            let newValue = evt.target.value;

            if (newValue.length === 1 && newValue === '0') {
                this.inputvalue[index].value = '';
                evt.target.value = '';
            } else if (evt.data == '0' && newValue.startsWith('0')) {
                evt.target.value = Number(this.inputvalue[index].value);
            } else if (/^\d+$/.test(newValue)) {
                this.inputvalue[index].value = Number(newValue);
            } else {
                if (newValue !== '') {
                    evt.target.value = Number(this.inputvalue[index].value);
                } else {
                    this.inputvalue[index].value = '';
                }
            }
        },

        selectInputValue(index) {
            this.selectedValue =  true;    
            let newinputvalue = JSON.parse(JSON.stringify(this.inputvalue)) 
            this.inputvalue =  newinputvalue
            for (let i = 0; i < this.inputvalue.length; i++) {
                this.inputvalue[i].isSelected = false;
            }
            this.inputvalue[index].isSelected = true;
        },
        resetClickBet(){
             if (this.getClickBetValue()) {
                    this.inputvalue = this.getClickBetValue();
                }
                else {
                    this.inputvalue = [
                        {
                            value: 1000,
                            isSelected: true,
                        },
                        {
                            value: 2000,
                            isSelected: false,
                        },
                        {
                            value: 3000,
                            isSelected: false,
                        },
                        {
                            value: 4000,
                            isSelected: false,
                        }
                    ]
                }
        },
        saveClickBetValue() {
            this.loading = true;
            if(this.inputvalue.some(obj => obj.isSelected)){
                let flag = false;
            for (let i = 0; i < this.inputvalue.length; i++) {
                if(this.inputvalue[i].value < 100 || this.inputvalue[i].value > 5000) {
                    flag = true;
                }
            }
            if(flag){
                // this.resetClickBet();
                this.$emit('error-modal',this.translatedLangData('value-btw-100-5000', 'Value should be in between 100 to 5000.'));
                this.loading = false;
                this.selectedValue =  false;   
                if(this.getClickBetValue()){
                    this.inputvalue = JSON.parse(JSON.stringify([...this.getClickBetValue()]))
                }
                return;
            }
            let stakearray = [];
            this.inputvalue.forEach(item => {
                stakearray.push(item.value);
            });
            let uniqueValues = new Set(stakearray);
            if(uniqueValues.size !== stakearray.length){
                this.$emit('error-modal',this.translatedLangData('repeated-stake-values-are-not-allowed', 'Repeated stake values are not allowed!'));
                this.loading = false;
                this.selectedValue =  false;   
                if(this.getClickBetValue()){
                    this.inputvalue = JSON.parse(JSON.stringify([...this.getClickBetValue()]))
                }
            }else{
            this.clickBetModal.hide();
            this.$store.dispatch('setClickBetValue', [...this.inputvalue]);
            this.selectedValue =  false;    
            this.$emit('success-modal',this.translatedLangData('clickbet-updated', 'Click bet value updated successfully.'));
            this.loading = false;
            this.$refs.close_btn.click();
            }
         }
        },
        closeModal(){
            this.selectedValue =  false;   
            if(this.getClickBetValue()){
                this.inputvalue = JSON.parse(JSON.stringify([...this.getClickBetValue()]))
            }
            // this.resetClickBet();
            this.$emit('close-modal',this.$store.getters.clickBetActive.isSelected?this.$store.getters.clickBetActive.isSelected:this.$store.getters.clickBetActive)
        }
    }
};
</script>
<style scoped>
.border-input {
    border: 2px solid  !important;
}
.min-max-section{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 10px;
    text-transform: capitalize;
}
.min-max-con h6{
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    color: var(--text-grey-color);
}
.min-max-icon{
    margin: 0px 5px;

}
.sumbit-bet-btn{
    background: var(--primary-color) !important;
    color: white !important;
}
.btn-clickbet-loader{
    font-size: 0 !important;
}
</style>