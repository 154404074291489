<template>
    <div class="modal fade modal-footer-sticky" id="referenceModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>Credit reference</h3>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="fa-solid fa-times"></span></button>
                </div>
                <div class="modal-body">
                    <div class="thm-heading winstatus-heading text-center d-inline-block w-100 mt-3 mb-3">
                        <h1>$1000.00</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CreditReference",
};
</script>