<template>
    <div class="modal fade " id="Register" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="background-filter">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <figure class="logo-pop-up">
                            <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="pop-logo">
                        </figure>
                        <button @click="refreshData()" type="button" ref="close_btn" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img loading="lazy" src="@/assets/images/close-btn.webp" alt="close-btn">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="registration-form">
                            <h2 class="register-title">{{ translatedLangData('register-account','Register Account')}}</h2>
                            <p class="create t-center reg-subtitle">{{ translatedLangData('create-account-with-mobile-number','Create Account With Mobile Number')}}</p>
                            <hr class="form-divider-line reg-hr">
                            <div class="get-mob">
                                <label for="" class="form-cmn-title">{{ translatedLangData('mobile-number','Mobile Number')}}*</label>
                                <div class="phone-inpt-sec">
                                    <div class="slt-country-code">
                                        <!-- <select v-model="form.code" class="form-select" id="autoSizingSelect"
                                            :disabled="otpSent" :class="{ 'enabled': !otpSent, 'disabled': otpSent }"> -->
                                        <select v-model="form.code" class="form-select" id="autoSizingSelect"
                                        :disabled="timerRunning">
                                            <option :value="allowedCountry.phonecode"
                                                v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                        </select>
                                    </div>
                                    <div class="input-number-box">
                                        <input type="tel" v-model="form.mobileNo" maxlength="10" minlength="10" 
                                            :placeholder="translatedLangData('enter-mobile-number','Enter Mobile Number')" @input="handleInput($event)"
                                            @keypress="specialCharValidation($event)" class="form-control add"
                                            :disabled="timerRunning">
                                    </div>
                                </div>
                            </div>

                            <div class="thm-heading justify-content-start password-wrong-con"
                                v-if="showErrorMobile && form.mobileNo == ''">
                                <p class="wrong-color">{{ translatedLangData('please-enter-mobile-number','Please Enter Mobile Number.')}}</p>
                            </div>
                            <div class="thm-heading justify-content-start" v-if="showErrorMobileLength">
                                 <p class="wrong-color">{{ translatedLangData('please-enter-valid-mobile-number','Please Enter Valid Mobile Number.')}}</p>
                            </div>

                            <div class="mak-gin mo-number-right mt-3">
                                <p v-if="timerRunning" style="padding-right: 5px;">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</p>
                                <button :disabled="loadingOtp || timerRunning" @click="sendOtpCall()"
                                    class="btn cmn-bdr-btn otp-btn">
                                    {{ resendOtp ? translatedLangData('resend-otp' , 'Resend OTP') : translatedLangData('get-otp' , 'Get OTP') }} 
                                </button>
                            </div>
                            <div class="get-mob for" v-if="isOtpApiCalled">
                                <label for="" class="form-cmn-title">{{translatedLangData('otp' , 'OTP') }}*</label>
                                <input v-model="form.otp" type="text" class="form-control" maxlength="6" :placeholder="translatedLangData('enter-otp','Enter OTP')"
                                    @input="validateOTP">
                                <div class="inp-img"><img loading="lazy" src="@/assets/images/key.webp" alt="key"></div>
                            </div>
                            <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                v-if="showValidationError && form.otp == ''">
                                <p class="wrong-color">{{translatedLangData('please-enter-otp' , 'Please Enter OTP.') }}</p>
                            </div>

                            <div class="get-mob for pass-m-top">
                                <label for="" class="form-cmn-title">{{ translatedLangData('user-id','User ID')}}*</label>
                                <input type="text" v-model="form.userName" class="form-control" @input="filterSpaces"
                                    :placeholder="translatedLangData('enter-user-id','Enter User ID')" minlength="6" maxlength="25">
                                <div class="inp-img"><img loading="lazy" src="@/assets/images/user-icon.webp" alt="user-icon"></div>
                            </div>

                            <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                v-if="showValidationError && form.userName == ''">
                                <p class="wrong-color">{{translatedLangData('please-enter-user-id','Please Enter User ID.')}}</p>
                            </div>
                            <div class="thm-heading justify-content-start"
                                v-if="showUserIDError && form.userName !=''">
                                <p class="wrong-color">{{translatedLangData('userid-must-contain-alphanumeric-and-between-6-to-25-characters','User ID Must Contain Alphanumeric And Between 6 To 25 Characters.')}}</p>
                            </div>

                            <div class="get-mob for">
                                <label for="" class="form-cmn-title">{{translatedLangData('password','Password')}}*</label>
                                <input :type="passwordFieldType" v-model="form.password" class="form-control" id="inputPass4"
                                    :placeholder="translatedLangData('enter-pass','Enter Password')" @input="validatePassword" maxlength="20">
                                <div class="inp-img"><img loading="lazy" src="@/assets/images/pass.webp" alt="pass"></div>
                                <div class="eye-icn-sec" @click="togglePasswordVisibility('password')">
                                    <img loading="lazy" v-if="passwordFieldType === 'password'" src="@/assets/images/eye-close.png">
                                    <img loading="lazy" v-else src="@/assets/images/eye-open.webp" alt="eye-open">
                                </div>
                            </div>

                            <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                v-if="showValidationError && form.password == ''">
                                <p class="wrong-color">{{translatedLangData('please-enter-password','Please Enter Password.')}}</p>
                            </div>

                            <div class="thm-heading justify-content-start">
                                <p class="wrong-color" v-if="passwordCapitalCaseValidation && form.password">{{translatedLangData('password-capital-case','Must Contain At Least 1 In Capital Case.')}}</p>
                            </div>
                            <div class="thm-heading justify-content-start">
                                <p class="wrong-color" v-if="passwordValidation">{{translatedLangData('must-between-8-20-characters-and-should-be-alph','Must Be Between 8-20 Characters And Should Be Alphanumeric.')}}</p>
                            </div>
                            <div class="get-mob for">
                                <label for="" class="form-cmn-title">{{translatedLangData('confirm-password','Confirm Password')}}*</label> 
                                <input :type="confPasswordFieldType" @input="validateConfirmPassword" v-model="form.confirmPassword" class="form-control" id="inputPass4"
                                    :placeholder="translatedLangData('enter-conf-password','Enter Confirm Password')">
                                <div class="inp-img"><img loading="lazy" src="@/assets/images/pass.webp" alt="pass"></div>
                                <div class="eye-icn-sec" @click="togglePasswordVisibility()">
                                    <img loading="lazy" v-if="confPasswordFieldType === 'password'" src="@/assets/images/eye-close.png">
                                    <img loading="lazy" v-else src="@/assets/images/eye-open.webp" alt="eye-open">
                                </div>
                            </div>
                            <div class="thm-heading justify-content-start mt-2 password-wrong-con">
                                <p v-if="showValidationError && form.confirmPassword == ''" class="wrong-color">
                                    {{translatedLangData('please-enter-confirm-password','Please Enter Confirm Password.')}}</p>
                                <p v-if="form.password != form.confirmPassword && form.confirmPassword!=''" class="wrong-color">
                                    {{translatedLangData('password-conf-pas-not-matched','Password And Confirm Password Not Matched.')}}
                                </p>
                            </div>

                            <div class="get-mob for">
                                <label for="" class="form-cmn-title">{{translatedLangData('refer-code','Refer Code')}}</label>
                                <input type="text" v-model="form.referCode" class="form-control" id="inputPass4"
                                    :placeholder="translatedLangData('enter-refer-code','Enter Refer Code')" @input="filterInput" :disabled="checkUrlRefer">
                                    <div class="inp-img"><img loading="lazy" src="@/assets/images/refer-icon.webp" alt="refer-icon"></div>
                                </div>
                                <!-- <div class="thm-heading justify-content-start mt-2 password-wrong-con">
                                    <p v-if="showReferCodeError" class="wrong-color">
                                        The Refer code must be alphanumeric and should be of 6 characters.
                                    </p>
                                </div> -->
                            <div class="form-group">
                                <a @click="signUpCall()" class="btn btn-submit btn-login" :class="{ 'btn-loading': loadingSignUp }">
                                    <span><b></b><b></b><b></b></span>
                                    {{translatedLangData('register','Register')}} 
                                    <!-- <i class=" ml-2 fas fa-sign-in-alt"></i> -->
                                </a>
                            </div>
                            <div v-if="siteSettings && siteSettings.social_link && siteSettings.social_link.whatsappno1"
                                class="whats-app">
                                <p class="create t-center">
                                    {{translatedLangData('get-your-ready-made-id-from-whatsapp','Get Your Ready-Made ID From Whatsapp')}}
                                </p>
                                <div class="button-whatsapp">
                                    <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank"
                                        class="btn-whatsapp"> <img loading="lazy" src="@/assets/images/whatsapp.webp" alt="whatsapp"> {{translatedLangData('whatsapp-now','Whatsapp Now')}}
                                    </a>
                                </div>
                            </div>
                            <p class="forpass-in ">{{translatedLangData('already-have-account','Already Have Account?')}}<a @click="showLogin(), closeModal()">{{translatedLangData('login','Login')}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { Modal } from 'bootstrap';

export default {
    name: "SignUp",
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings.country_code ? this.siteSettings.country_code : 91,
                mobileNo: "",
                otp: '',
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            allowedCountries: [91, 92, 880, 977, 971, 965],
            captchaData: null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            loginModal: null,
            otpSent: false, // Added variable to track OTP sent status
            passwordFieldType: 'password',
            confPasswordFieldType: 'password',
            showUserIDError: false,
            showErrorMobileLength: false,
            passwordCapitalCaseValidation: false,
            showReferCodeError:false,

        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        checkUrlRefer() {
            return (window.location.href).includes('refer_code')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0,
                this.otpSent = false,
                this.passwordFieldType = 'password',
                this.confPasswordFieldType = 'password',
                this.showUserIDError = false,
                this.showErrorMobileLength = false
        },
        sendOtpCall() {
            if (!this.loadingOtp) {
                if (!this.form.mobileNo) {
                    this.showErrorMobile = true;
                } 
                else if(this.showErrorMobileLength){
                    return
                }
                else {
                    this.sendOtpServiceCall();
                }
            }
        },
        captchaDataUpdate(item) {
            this.captchaData = item;
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            api.post(apiName.SEND_OTP, {
                mobile_number: this.form.mobileNo,
                cncode: this.form.code,
            }).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.otpSent = true; // OTP sent successfully, disable phone number field
                        this.form.captchaText = '';
                        this.showErrorMobile = false;
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.showUserIDError = false,
                        this.showErrorMobileLength = false,
                        this.setResendTimer();
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error)
                    this.showErrorModalFunc(error[0])
            });
        },
        validatePassword() {
            this.checkEmptyField();
            const password = this.form.password;
            const minLength = 8;
            const maxLength = 20;
            const hasCapitalLetter = /[A-Z]/.test(password);
            if (!password.trim()) {
                this.passwordValidation = false;
                return; 
            }
            if (!hasCapitalLetter) {
                    this.passwordCapitalCaseValidation = true;
                } else {
                    this.passwordCapitalCaseValidation = false;
                }
            this.form.password = this.form.password.replace(/[^a-zA-Z0-9]/g, '');
            const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*\d).*$/;
            if (password.length < minLength || password.length > maxLength){
                this.passwordValidation = true;
            } else {
                if (!password.match(alphanumericRegex)){
                    this.passwordValidation = true;
                } else {
                    this.passwordValidation = false;
                }
            }
        },
        checkEmptyField(){
            if(this.form.userName == "" || this.form.password == "" || this.form.confirmPassword == "") {
                this.showValidationError = true;
            }
        },
        validateConfirmPassword(){
            this.showValidationError = true;
            this.form.confirmPassword = this.form.confirmPassword.replace(/[^a-zA-Z0-9]/g, '');
        },
        // validateReferCode() {
        //     const alphanumericRegex = /^[a-zA-Z0-9]+$/;
        //     const minLength = 6;
        //     this.form.referCode = this.form.referCode.replace(/[^a-zA-Z0-9]/g, '');

        //     if (this.form.referCode.length < minLength) {
        //         this.showReferCodeError = true;
        //     } else {
        //         if (!this.form.referCode.match(alphanumericRegex)) {
        //             this.showReferCodeError = true;
        //         } else {
                    
        //             const onlyNumbers = /^\d+$/.test(this.form.referCode);
        //             const onlyAlphabets = /^[a-zA-Z]+$/.test(this.form.referCode);
        //             if ((onlyNumbers || onlyAlphabets) && this.form.referCode.length === minLength) {
        //                 this.showReferCodeError = true;
        //             } else {
        //                 this.showReferCodeError = false;
        //             }
        //         }
        //     }
        // },
        filterInput(event) {
        const value = event.target.value;
        const filteredValue = value.replace(/[^0-9]/g, '');
        this.form.referCode = filteredValue;
        },
        signUpCall() {
            if (!this.isOtpApiCalled)
            { 
                this.showErrorMobile=true
                return
            }
            if (!this.loadingSignUp) {
                let o = this.getOtp();
                this.passwordValidation = false;
                this.alphanumeric = false;
                const password = this.form.password;
                const minLength = 8;
                const maxLength = 20;
                const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*\d).*$/;
                if (password) {
                    if (password.length < minLength || password.length >= maxLength){
                        this.passwordValidation = true;
                    } else {
                        if (!password.match(alphanumericRegex)){
                            this.passwordValidation = true;
                        } else {
                            this.passwordValidation = false;
                        }
                    }
                }
                if (this.form.mobileNo == '') {
                    this.showErrorMobile = true;
                }
                 else if (o.length != 6 || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '') {
                    this.showValidationError = true;
                } else if (this.form.password != this.form.confirmPassword) {
                    this.passwordValidation = true;
                } else if(this.showUserIDError || this.passwordCapitalCaseValidation || this.passwordValidation){
                    return 
                }
                else 
                 {
                    this.signUpServiceCall();
                }
            }
        },
        async signUpServiceCall() {
            this.loadingSignUp = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "cncode": this.form.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            // this.$refs.open-modal.click();
                            this.refreshData();
                            this.closeModal();
                            setTimeout(()=>{
                                this.showLogin()
                            },2000)
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                if (error)
                    this.showErrorModalFunc(error[0]);
            });
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        handleOtpBoxKeyDown(event, index) {
            if (event.key !== "Tab" && event.key !== "ArrowRight" && event.key !== "ArrowLeft") {
                event.preventDefault();
            }
            if (event.key === "Backspace") {
                this.form.otp[index] = null;
                if (index) {
                    this.$refs['otp-box-' + (index - 1)].focus();
                }
                return;
            }
            if ((new RegExp('^([0-9])$')).test(event.key)) {
                this.form.otp[index] = event.key;
                if (index != 5) {
                    this.$refs['otp-box-' + (index + 1)].focus();
                }
            }
        },
        showLogin() {
            this.loginModal.show();
        },
        specialCharValidation(e) {
            if (e.keyCode >= 48 && e.keyCode <= 57) {
                return false;
            }
            const notAllowedKeys = ["e", "E", ".", "x", "X"];
            const keyPressed = String.fromCharCode(e.keyCode);

            if (notAllowedKeys.includes(keyPressed)) {
                e.preventDefault();
            }
        },
        handleInput(event) {
            this.form.mobileNo = event.target.value.replace(/\D/g, ''); // Extract only numeric characters from the input value
            if (this.form.mobileNo.length < 10 && this.form.mobileNo.length > 0) {
                this.showErrorMobileLength = true;
                this.showErrorMobile=true;
            } else {
                this.showErrorMobileLength = false;
            }
        },
        togglePasswordVisibility(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            } else {
                this.confPasswordFieldType = this.confPasswordFieldType === 'password' ? 'text' : 'password';
            }   
        },
        validateOTP() {
            this.form.otp = this.form.otp.replace(/[^0-9]/g, '');
        },
        filterSpaces(event) {
            this.form.userName = event.target.value.replace(/[^\w]|_/g, '');
            this.checkEmptyField();
            const minLength = 6;
            const maxLength = 25;
            const containsAlphabetsAndNumbers = /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(this.form.userName);
            const containsSpaces = /\s/.test(this.form.userName); 

            if (containsSpaces) {
                this.form.userName = this.form.userName.replace(/\s/g, '');
            }
            this.showUserIDError = this.form.userName.length < minLength || this.form.userName.length > maxLength || !containsAlphabetsAndNumbers;
        
        }
    },
    mounted() {
        console.log((window.location.href).includes('refer_code'));
        if(this.$route.name == 'Home' && (window.location.href).includes('refer_code') && !this.$store.getters.isAuthenticated && this.siteSettings?.business_type == 2){
            setTimeout(()=> {
                let loginModal = new Modal('#Register');
                loginModal.show();
            }, 1000)
        }
        document.body.classList.add('login-body-bg', 'inner-body');
        this.loginModal = new Modal('#myModal');
        document.body.classList.add('login-body-bg', 'inner-body');
    },
    unmounted() {
        document.body.classList.remove('login-body-bg', 'inner-body');
    }
};
</script>
<style scoped>
.reg-hr{
    height: 2.3px !important;
}

@keyframes stretch {
    0% {
        transform: scale(0.5);
        background-color: #B5DDF0;
    }

    50% {
        background-color: #CFE9F6;
    }

    100% {
        transform: scale(1);
        background-color: #FFFFFF;
    }
}

.btn.btn-loading {
    font-size: 0;
}

.btn.btn-loading span {
    display: flex;
    justify-content: center;
}

.btn.btn-loading span b {
    animation-direction: alternate;
    animation-duration: 0.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: stretch;
    animation-play-state: running;
    animation-timing-function: ease-out;
    border-radius: 100%;
    display: block;
    height: 10px;
    margin: 0 1px;
    width: 10px;
    animation-delay: 0.1s;
    margin: 0 5px;
}

.btn.btn-loading span b:first-child {
    animation-delay: 0s;
    margin: 0;
}

.btn.btn-loading span b:last-child {
    animation-delay: 0.2s;
    margin: 0;
}

.btn span {
    display: none;
}

.placed-bet-btn.btn-loading {
    padding: 10px;
}

</style>