
export const USER_PROFILE='api/user-profile'
export const LOGOUT_API= '/api/logout'
export const AUTH_API= '/api/auth-status'

export const SITE_SETTINGS='api/site-settings';
export const AUTH_STATUS='api/auth-status';
export const GET_FAVOURITE_LIST='api/favorite-list';
export const WALLET_BALANCE='wallet';
export const AUTH_REFRESH='api/auth-refresh';
export const GET_BONUS_LOGS= 'api/get-bonus-log';
export const getVersionStatus= 'api/site-status-version';
export const v1_SITE_SETTINGS= 'api/v1-site-settings';
export const SUBSCRIBE_NOTIFICATION= 'api/subscribe-notification';
export const NOTIFICATIONS_LIST= 'get/all/notification/history';
