const SportsModule = () => import(/* webpackChunkName: "sports-module" */ './views/Module.vue');
// const Sports = () => import(/* webpackChunkName: "sports" */ './views/Sports.vue');
const Inplay = () => import(/* webpackChunkName: "sport-event-detail" */ './views/Inplay.vue');
const SportsEventDetail = () => import(/* webpackChunkName: "sport-event-detail" */ './views/SportEventDetail.vue');
const SportsBook = () => import(/* webpackChunkName: "SportsBook" */ './views/SportsBook.vue');

const SportsRoutes = {
  path: '/',
  component: SportsModule,
  children: [
    {
      path: 'home',
      name: 'Inplay',
      component: Inplay
    },
    {
      path: 'home',
      name: 'Featured',
      component: Inplay
    },
    {
      path: 'sports/:type/:id',
      name: 'sports',
      component: Inplay
    },
    {
      path: 'sports-event-detail/:event_id',
      name: 'SportsEventDetail',
      component: SportsEventDetail
    },
    {
      path:'sports-book',
      name:'SportsBook',
      component:SportsBook
    }
    // {
    //   path: 'sports',
    //   name: 'Sports',
    //   component: Sports
    // },

  ],
}

export default SportsRoutes;