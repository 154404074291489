<template>
    <div class="modal fade " id="staticBackdrop-two" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="background-filter">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <figure class="logo-pop-up">
                            <img loading="lazy" src="@/assets/images/tigerexch-logo.webp" alt="tigerexch-logo">
                        </figure>
                        <button ref="close_btn" @click="refreshData()" type="button" class="btn-close"
                            data-bs-dismiss="modal" aria-label="Close">
                            <img loading="lazy" src="@/assets/images/close-btn.webp" alt="close-btn">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="registration-form">
                            <h2 class="register-title">{{ translatedLangData('forgot-password-cap','FORGOT PASSWORD ?')}}</h2>
                            <!-- <p class="create">
                                Enter Your Phone And We'll Send <br>
                                You A Otp To Get Back Into Your Account.
                            </p> -->
                            <hr class="form-divider-line">
                            <div class="get-mob">
                                <label for="" class="form-cmn-title">{{ translatedLangData('mobile-number','Mobile Number')}}*</label>
                                <div class="phone-inpt-sec">
                                    <div class="slt-country-code">
                                        <div class="col-auto">
                                            <select v-model="form.code" class="form-select" id="autoSizingSelect">
                                                <option :value="allowedCountry.phonecode"
                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="input-number-box">
                                        <div class="col-auto">
                                            <input style="padding-left: 15px;" v-model="form.mobileNo" type="text" @input="validateMobile($event)"
                                                maxlength="10" minlength="10" class="form-control add" id="autoSizingInput"
                                                :placeholder="translatedLangData('enter-mobile-number','Enter Mobile Number')">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                v-if="showErrorMobile && form.mobileNo == ''">
                                <p class="wrong-color">{{translatedLangData('please-enter-valid-phone-number','Please Enter Valid Phone Number.')}}</p>
                            </div>

                           <div class="mak-gin mo-number-right mt-2">
                                <p v-if="timerRunning">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</p>
                                <button :disabled="loadingOtp || timerRunning"  @click="sendOtpCall()" class="btn cmn-bdr-btn otp-btn">
                                    {{ resendOtp ? translatedLangData('resend-otp' , 'Resend OTP') : translatedLangData('get-otp' , 'Get OTP') }}   
                                </button>
                            </div>

                            <div class="get-mob for" v-if="isOtpApiCalled">
                                <label></label>
                                <input v-model="form.otp" type="text" @input="validateOTP($event)" minlength="6" maxlength="6" class="form-control" id="inputPassword2" :placeholder="translatedLangData('enter-otp','Enter OTP')">
                                <div class="inp-img"><img loading="lazy" src="@/assets/images/key.webp" alt="key"></div>
                            </div>

                            <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                v-if="passwordValidation && getOtp().length < 6">
                                <p class="wrong-color">{{translatedLangData('please-enter-otp' , 'Please Enter OTP.') }}</p>
                            </div>

                            <div class="get-mob for pass-m-top">
                                <label for="" class="form-cmn-title">{{translatedLangData('password','Password')}}*</label>
                                <input :type="passwordFieldType" v-model="form.password" @input="validatePassword()" class="form-control" minlength="8" maxlength="20" id="inputPass4" :placeholder="translatedLangData('enter-pass','Enter Password')">
                                <div class="inp-img password-icon"><img loading="lazy" src="@/assets/images/pass.webp" alt="pass"></div>
                                <div class="eye-icn-sec" @click="toggleShow('password')">
                                    <img loading="lazy" v-if="passwordFieldType=='text'" src="@/assets/images/eye-open.webp" alt="eye-open">
                                    <img loading="lazy" v-else src="@/assets/images/eye-close.png" alt="eye-close">
                                </div>
                            </div>

                            <div v-if="passwordValidation && form.password == ''" class="thm-heading justify-content-start mt-2    password-wrong-con">
                                <p class="wrong-color">{{translatedLangData('please-enter-password','Please Enter Password.')}}</p>
                            </div>
                            <div class="justify-content-start set-err-text pass-vali-div"
                                v-if="passwordValid">
                                <img class="succ-wrong-img" v-if="!passwordValidation1" src="@/assets/images/request-right-icon.webp" alt="request-right-icon">
                                <img class="succ-wrong-img" v-if="passwordValidation1" src="@/assets/images/wrong-icon.webp" alt="wrong-icon">
                                <p :class="passwordValidation1 ? 'wrong-color':'success-color'">{{translatedLangData('must-between-8-20-characters-and-should-be-alph','Must Be Between 8-20 Characters And Should Be Alphanumeric.')}}</p>
                            </div>
                            <div class="justify-content-start set-err-text pass-vali-div"
                                v-if="passwordValid">
                                <img class="succ-wrong-img" v-if="passwordValidation2" src="@/assets/images/request-right-icon.webp" alt="request-right-icon">
                                <img class="succ-wrong-img" v-if="!passwordValidation2" src="@/assets/images/wrong-icon.webp" alt="wrong-icon">
                                <p :class="!passwordValidation2 ? 'wrong-color':'success-color'">{{translatedLangData('password-capital-case','Must Contain At Least 1 In Capital Case.')}}</p>
                            </div>

                            <div class="get-mob for">
                                <label for="" class="form-cmn-title">{{translatedLangData('confirm-password','Confirm Password')}}*</label>
                                <input :type="confPasswordFieldType" v-model="form.confirmPassword" class="form-control" id="inputPass5" minlength="8" maxlength="20"
                                    :placeholder="translatedLangData('enter-conf-password','Enter Confirm Password')" @input="validateConfirmPassword" @keyup="checkPasswordMatch" >
                                <div class="inp-img password-icon"><img loading="lazy" src="@/assets/images/pass.webp" alt="pass"></div>
                                <div class="eye-icn-sec" @click="toggleShow('conf-password')">
                                    <img loading="lazy" v-if="confPasswordFieldType=='text'" src="@/assets/images/eye-open.webp" alt="eye-open">
                                    <img loading="lazy" v-else src="@/assets/images/eye-close.png" alt="eye-close">
                                </div>
                            </div>

                            <div class="thm-heading justify-content-start mt-2 password-wrong-con">
                                <p v-if="passwordValidation && form.confirmPassword == ''" class="wrong-color">
                                    {{translatedLangData('please-enter-confirm-password','Please Enter Confirm Password.')}}</p>
                                <p v-if="confirmPasswordValidation  && form.password != form.confirmPassword" class="wrong-color">
                                    {{translatedLangData('password-mismatch','Password Mismatch.')}}
                                </p>
                            </div>
                            <div class="form-group">
                                <a @click="forgetPasswordCall()" class="btn btn-submit btn-login">
                                     {{translatedLangData('update-password','Update Password')}}
                                </a>
                            </div>


                            <p class="forpass-in">{{translatedLangData('remember-your-password','Remember Your Password')}}? <a data-bs-toggle="modal" href="#myModal">{{translatedLangData('login','Login')}}</a></p>
                            <p class="forpass-in"><a data-bs-toggle="modal" href="#Register">{{translatedLangData('register-a-new-account','Register A New Account')}} </a></p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';

export default {
    name: "ForgetPassword",
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                otp: [],
                password: "",
                confirmPassword: "",
                captchaText: ''
            },
            loadingOtp: false,
            loadingUpdate: false,

            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            isOtpApiCalled: false,
            captchaData:null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            passwordFieldType:'password',
            confPasswordFieldType:'password',
            passwordFlag: true,
            passwordValidation1 : false,
            passwordValidation2 : false,
            confirmPasswordValidation: false,
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
    methods: {
        checkPasswordMatch(event) {
            let confirmPasswordVal = this.form.confirmPassword;
            if(confirmPasswordVal.length > 0) {
                confirmPasswordVal = event.target.value.replace(/\s/g, ''); // Remove any spaces from the input
                if (confirmPasswordVal != this.form.password) {
                    this.confirmPasswordValidation = true;
                } 
            } else {
                this.confirmPasswordValidation = false;
            }
        },
        validateOTP(event){
            this.form.otp = event.target.value.replace(/[^0-9]/g, '');
        },
        validateConfirmPassword(){
            this.form.confirmPassword = this.form.confirmPassword.replace(/\s/g, '');
            this.form.confirmPassword = this.form.confirmPassword.replace(/[^a-zA-Z0-9]/g, '');
        },
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: [],
                password: "",
                confirmPassword: "",
                captchaText: ''
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0,
                this.passwordValidation1 = false,
                this.passwordValidation2 = false
        },
        captchaDataUpdate(item) {
            this.captchaData = item;
        },
        sendOtpCall() {
            if (this.form.mobileNo == "" ) {
                this.showErrorMobile = true;
            }
            else {
                this.sendOtpServiceCall();
            }
        },
        handleOtpBoxKeyDown(event, index) {
            if (event.key !== "Tab" && event.key !== "ArrowRight" && event.key !== "ArrowLeft") {
                event.preventDefault();
            }
            if (event.key === "Backspace") {
                this.form.otp[index] = null;
                if (index) {
                    this.$refs['otp-box-' + (index - 1)].focus();
                }
                return;
            }
            if ((new RegExp('^([0-9])$')).test(event.key)) {
                this.form.otp[index] = event.key;
                if (index != 5) {
                    this.$refs['otp-box-' + (index + 1)].focus();
                }
            }
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code,
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.form.captchaText = '';
                        this.showErrorMobile = false;
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                        // this.closeModal();
                    }

                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error) {
                    console.log("Error : ", error);
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        forgetPasswordCall() {
            if (!this.isOtpApiCalled)
                return
            let o = this.getOtp();

            this.passwordValidation = false;
            if (this.form.mobileNo == "") {
                this.showErrorMobile = true;
            }
            else if (o.length != 6 || this.form.password == '' || this.form.confirmPassword == '') {
                this.passwordValidation = true;
            }
            else if (this.form.password != this.form.confirmPassword) {
                this.passwordValidation = true;
            }
            else {
                this.forgotServiceCall();
            }
        },
        async forgotServiceCall() {
            this.loadingUpdate = true;
            let data = {
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.getOtp(),
                "cncode": this.form.code,
            };
            api.post(apiName.FORGOT_PASSWORD, data).then(response => {
                this.loadingUpdate = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            // this.refreshData();
                            this.closeModal()
                        }
                    }
                }
            }).catch(error => {
                this.loadingUpdate = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {

            this.$refs.close_btn.click();
        },
        validateMobile(event){
            this.form.mobileNo = event.target.value.replace(/\D/g, '');
        },
        toggleShow(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = (this.passwordFieldType === 'text') ? 'password' : 'text';

            } else {
                this.confPasswordFieldType = (this.confPasswordFieldType === 'text') ? 'password' : 'text';
            }
        },
        validatePassword(){
            if(this.passwordFlag){
                this.passwordFlag = false;
                this.passwordValid = true;
            }
            const password = this.form.password;
            const minLength = 8;
            const maxLength = 20;
            this.form.password = password.replace(/[^a-zA-Z0-9]/g, '');
            const containsAlphabets = /^(?=.*[a-zA-Z]).*$/.test(password);
            const containsNumbers = /^(?=.*\d).*$/ .test(password);
            const containsSpaces = /\s/.test(password); // Check if password contains spaces
            this.passwordValidation1 = password.length < minLength || password.length > maxLength || !containsAlphabets || !containsNumbers;
            this.passwordValidation2 = /[A-Z]/ .test(this.form.password);
            if (containsSpaces) {
                this.form.password = password.replace(/\s/g, '');
            }
        },

    },
};
</script>
<style scoped>
.pass-vali-div {
    display: flex;
    gap: 5px;
    margin-top: 5px;
}
.pass-vali-div p{
    font-size: 12px;
    font-weight: bold;
}
.succ-wrong-img {
    width: 15px;
    height: 15px;
}
.success-color{
    color: #27ae60;
}
</style>