<template>
    <div class="off-canvass-section">
        <div class="offcanvas offcanvas-start" id="open-side-toggle-m">
            <SideMenu></SideMenu>
        </div>
    </div>
</template>

<script>
import SideMenu from './SideMenu.vue';

export default {
    name: "MobileSideMenu",
    components: { SideMenu }
};
</script>

<style scoped>
.offcanvas-start .show{
    visibility: visible;
}
</style>